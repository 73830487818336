<template scoped>
  <div>
    <el-form-item
      label="Provider"
      prop="sms.provider"
      :rules="rules.provider"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="provider_change"
        v-model="provider.sms.provider"
        :style="{ width: '100%' }"
        placeholder="Select the Provider"
      >
        <el-option
          v-for="item in supportProvider"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component
      :is="content"
      :endpoint="endpoint"
      :provider="provider"
    ></component>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    provider: Object,
    endpoint: String,
  },
  methods: {
    provider_change: function (val) {
      switch (val) {
        case "AccessYou":
          this.content = () =>
            import("./AccessYou/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
    validator_provider: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the provider should not be empty"));
      } else {
        callback();
      }
    },
  },
  created: function () {
    if (this.provider.sms.provider) {
      this.provider_change(this.provider.sms.provider);
    }
  },
  data: function () {
    return {
      content: null,
      supportProvider: [
        {
          name: "Access You",
          value: "AccessYou",
        },
      ],
      rules: {
        provider: [{ validator: this.validator_provider, trigger: "blur" }],
      },
    };
  },
};
</script>    